import isPast from 'date-fns/isPast';
import addDays from 'date-fns/addDays';

const isActiveUntil = (date) => !isPast(addDays(new Date(date), 1));

const specialEvents = [
  {
    href: 'https://contenido.roll-bits.com/katy-perry-25-abril-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n-z8s-g58-yqc-5w8-xpn-dh9-num/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/katy-perry-circle-25042025.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/katy-perry-banner-25042025.jpg',
    alt: 'Katy Perry 25 de Abril',
    descriptionText: `Katy Perry: 25 de Abril`,
    isSlider: true,
    isActive: isActiveUntil('2025-04-25'),
  },
  {
    href: 'https://contenido.roll-bits.com/katy-perry-26-abril-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n-z8s-g58-yqc-5w8-xpn-dh9-num-04u/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/katy-perry-circle-26042025.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/katy-perry-banner-26042025.jpg',
    alt: 'Katy Perry 26 de Abril',
    descriptionText: `Katy Perry: 26 de Abril`,
    isSlider: true,
    isActive: isActiveUntil('2025-04-26'),
  },
  {
    href: 'https://contenido.roll-bits.com/katy-perry-01-mayo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n-z8s-g58-yqc-5w8-xpn-dh9-num-04u-via/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/katy-perry-circle-01052025.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/katy-perry-banner-01052025.jpg',
    alt: 'Katy Perry 1 de Mayo',
    descriptionText: `Katy Perry: 1 de Mayo`,
    isSlider: true,
    isActive: isActiveUntil('2025-05-01'),
  },
  {
    href: 'https://contenido.roll-bits.com/katy-perry-02-mayo-2025-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-74l-8fz-yjy-fy1-ult-d5a-4lk-v4g-6r8-szb-3wr-63u-1li-7qb-75f-ich-5tf-2vc-4lq-au6-xa2-ezx-xts-moz-2k9-e7n-z8s-g58-yqc-5w8-xpn-dh9-num-04u-via-hrw/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/katy-perry-circle-02052025.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/katy-perry-banner-02052025.jpg',
    alt: 'Katy Perry 2 de Mayo',
    descriptionText: `Katy Perry: 2 de Mayo`,
    isSlider: true,
    isActive: isActiveUntil('2025-05-02'),
  },
  {
    href: 'https://contenido.roll-bits.com/ghost-24-sep-qln-x3f-926-rsd-oev-ja7-4x7-7ku-b4w-1vq-vu6-luj-meb-u8o-ru5-wsg-3kw-era-rk6-iag/',
    img: 'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/ghost-circle-240925.svg',
    banner:
      'https://rsrvms-vanda-prod.s3.us-east-1.amazonaws.com/funnel/events/ghost-banner-240925.jpg',
    alt: 'Ghost 24 de Septiembre',
    descriptionText: `Ghost: 24 de Septiembre`,
    isSlider: true,
    isActive: isActiveUntil('2025-09-24'),
  },
];

export default specialEvents;
